<template>
   <div v-if="pdv.periodoAberto && pdv.idOperador != dadosUsuario.usuarioId" class="card container-operador">
      <div class="w-100 text-center">
         <i class="fal fa-store-slash font-60 text-secondary opacity-50 d-block mb-3"></i>
         <h4 class="font-13 mt-4 mb-0 text-secondary text-uppercase">Caixa aberto com outro operador</h4>
         <p class="limitador-1 font-13 mt-4 mb-0 text-secondary">
            <i class="far fa-desktop color-theme font-10 me-1"></i> {{ pdv.nome == null ? 'Sem nome' : pdv.nome }}
         </p>
         <p class="limitador-1 font-13 mt-1 mb-0 text-secondary">
            <i class="far fa-store color-theme font-10 me-1"></i> {{ pdv.nomeLoja == null ? 'Sem loja' : pdv.nomeLoja }}
            <small class="mx-1"> | </small><i class="far fa-tag font-9 me-1"></i> {{ pdv.numeroLoja == null ? 'Sem número' : pdv.numeroLoja }}
         </p>
         <p class="limitador-1 font-13 mt-1 mb-2 text-secondary">
            <i class="far fa-user color-theme font-10 me-1"></i> {{ pdv.nomeOperador == null ? 'Sem operador(a)' : pdv.nomeOperador }}
         </p>
         <button class="btn btn-outline-secondary mt-4" @click="executarFuncao('Abrir/Fechar caixa')" v-if="dadosUsuario.usuarioPermissoes.includes('Caixa | Abrir/Fechar caixa')">Fechar caixa</button>
      </div>
   </div>

   <div v-else class="row" id="pdv">
      <div class="col-lg-4 px-0 h-100">
         <div class="card container-lateral-50 mb-1">
            <div class="card-body h-100 px-12 py-3">
               <div class="row h-100">
                  <div class="col-12">
                     <div class="input-group">
                        <span class="input-group-text" id="addProduto"><i class="far fa-search color-theme"></i></span>
                        <input type="text" class="form-control" @keyup="buscarProdutos" placeholder="Busque por nome do produto, código ou família" ref="addProduto" aria-describedby="addProduto" />
                     </div>
                  </div>

                  <div class="col-12 mt-12 container-pesquisa">
                     <div class="cards-125">
                        <produto v-for="(produto, index) in pesquisaProduto.resultado" :key="index" :produto="produto" :index="index" :showSelect="true"
                           @select="salvarProdutoTemp($event)" />
                     </div>
                     <div class="w-100 mt-5 text-center" v-if="pesquisaProduto.resultado.length == 0">
                        <i class="fal fa-align-slash font-60 text-secondary opacity-50 d-block mb-3"></i>
                        <h4 class="font-12 mt-4 mb-0 text-secondary text-uppercase">Nenhum resultado encontrado</h4>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         
         <div id="search-nav" class="d-block btn-group dropup">
            <button type="button" class="btn btn-primary dropdown-toggle d-block d-lg-none" data-bs-toggle="dropdown" data-bs-auto-close="false" aria-expanded="false">
               <i class="far fa-stream font-17 mx-auto"></i><i class="fal fa-times font-17 mx-auto"></i>
            </button>
            <div class="card dropdown-menu dropdown-menu-left">
               <div class="card-body p-12">
                  <div class="row h-100">
                     <!-- Informacoes PDV -->
                     <div class="col-sm-7 ps-0">
                        <div class="row">
                           <div class="col-12 mb-1 px-0">
                              <div class="card hpx-55 invert">
                                 <div class="card-body p-2 d-flex align-items-center">
                                    <div class="w-100">
                                       <h1 class="font-12 mb-1 text-uppercase limitador-1"><i class="far fa-desktop color-theme font-10 d-lg-none d-xxl-inline me-1"></i> Nome caixa</h1>
                                       <p class="font-12 mb-0 text-secondary text-end"><span class="limitador-1">{{ pdv.nome == null ? 'Sem nome' : pdv.nome }}</span></p>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div class="col-12 mb-1 px-0">
                              <div class="card hpx-55 invert">
                                 <div class="card-body p-2 d-flex align-items-center">
                                    <div class="w-100">
                                       <h1 class="font-12 mb-1 text-uppercase limitador-1"><i class="far fa-user color-theme font-10 d-lg-none d-xxl-inline me-1"></i> Operador(a)</h1>
                                       <p class="font-12 mb-0 text-secondary text-end"><span class="limitador-1">{{ pdv.nomeOperador == null ? 'Sem operador(a)' : pdv.nomeOperador }}</span></p>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div class="col-12 mb-1 px-0">
                              <div class="card hpx-55 invert">
                                 <div class="card-body p-2 d-flex align-items-center">
                                    <div class="w-100">
                                       <h1 class="font-12 mb-1 text-uppercase limitador-1"><i class="far fa-store color-theme font-10 d-lg-none d-xxl-inline me-1"></i> Loja</h1>
                                       <p class="font-12 mb-0 text-secondary text-end">
                                          <span class="limitador-1">{{ pdv.nomeLoja == null ? 'Sem loja' : pdv.nomeLoja }}<small class="mx-1"> | </small>
                                          <i class="far fa-tag font-9 me-1"></i> {{ pdv.numeroLoja == null ? 'Sem número' : pdv.numeroLoja }}</span>
                                       </p>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div class="col-12 mb-1 px-0">
                              <div class="card hpx-55 invert">
                                 <div class="card-body p-2 d-flex align-items-center">
                                    <div class="w-100">
                                       <h1 class="font-12 mb-1 text-uppercase limitador-1"><i class="far fa-address-card color-theme d-lg-none d-xxl-inline font-10 me-1"></i> Cliente</h1>
                                       <p class="font-12 mb-0 text-secondary text-end"><span class="limitador-1">{{ pdv.cpfCnpjCliente == null ? 'Não informado' : pdv.cpfCnpjCliente }}</span></p>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>

                     <!-- Opcoes -->
                     <div class="col-sm-5 px-0">
                        <div class="row">
                           <div class="col-12 mb-1 px-0">
                              <div class="card hpx-55 cursor-pointer" @click="setCliente">
                                 <div class="card-body p-2 d-flex justify-content-center align-items-center">
                                    <h1 class="font-12 my-1 text-center text-uppercase"><span class="limitador-2">Informar cliente</span></h1>
                                 </div>
                              </div>
                           </div>
                           <div class="col-12 mb-1 px-0">
                              <div class="card hpx-55 cursor-pointer" @click="executarFuncao('Limpar compra')">
                                 <div class="card-body p-2 d-flex justify-content-center align-items-center">
                                    <h1 class="font-12 my-1 text-center text-uppercase"><span class="limitador-2">Limpar compra</span></h1>
                                 </div>
                              </div>
                           </div>
                           <div class="col-12 mb-1 px-0">
                              <div class="card hpx-55 cursor-pointer" @click="executarFuncao('Efetuar sangria')">
                                 <div class="card-body p-2 d-flex justify-content-center align-items-center">
                                    <h1 class="font-12 my-1 text-center text-uppercase"><span class="limitador-2">Sangria</span></h1>
                                 </div>
                              </div>
                           </div>
                           <div class="col-12 mb-1 px-0">
                              <div class="card hpx-55 cursor-pointer" @click="executarFuncao('Abrir/Fechar caixa')">
                                 <div class="card-body p-2 d-flex justify-content-center align-items-center">
                                    <h1 class="font-12 my-1 text-center text-uppercase"><span class="limitador-2">Fechar caixa</span></h1>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>

      <!-- Produtos -->
      <div class="col-lg-8 px-0 h-100 ps-lg-1">
         <div class="card h-100 mb-1">
            <div class="card-body px-12 py-3 h-100">
               <div class="row mb-1 px-12">
                  <div class="wpx-50 me-3 d-none d-xxl-block">&nbsp;</div>
                  <div class="col-sm text-center text-sm-start"><label>Descrição/Código</label></div>
                  <div class="wpx-100 text-sm-center"><label>Quantidade</label></div>
                  <div class="wpx-100 text-center"><label>Unitário</label></div>
                  <div class="wpx-115 text-center d-none d-xl-block"><label>Subtotal</label></div>
                  <div class="wpx-90 ms-2">&nbsp;</div>
                  <div class="col-12"><hr class="border-theme mt-1 mb-2"></div>
               </div>

               <div class="container-produtos">
                  <produto v-for="(produto, index) in pdv.produtos" :key="index" :produto="produto" :index="index" :showSelect="false" 
                     @salvar="salvarProdutoTemp($event)" @remover="executarFuncao('Excluir item compra', $event)" />
               </div>

               <div class="row align-items-center">
                  <div class="col-12"><hr class="border-theme my-12"></div>
                  <div class="col-md-5 d-none d-md-block"></div>
                  <div class="col-8 col-sm-6 col-md-4 font-16 text-md-center">
                     <strong class="weight-500 me-4">TOTAL:</strong><small>R$</small> {{ pdv.totalProdutos == null ? '0,00' : parseFloat(pdv.totalProdutos).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}
                  </div>
                  <div class="col-4 col-sm-6 col-md-3">
                     <button class="btn btn-outline-primary w-100" :disabled="pdv.totalProdutos == 0" @click="abrirModalPgto">
                        <span class="font-12 my-1 text-center text-uppercase weight-500"><span class="limitador-1">Concluir <span class="d-none d-sm-inline">compra</span></span></span>
                     </button>
                  </div>
               </div>
            </div>
         </div>
      </div>

      <!-- modalPgto -->
      <div class="modal fade" id="modalPgto" tabindex="-1" aria-labelledby="modalPgtoLabel" aria-hidden="true" data-bs-focus="false">
         <div class="modal-dialog modal-md modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content">
               <div class="modal-header border-0">
                  <h5 class="modal-title" id="modalPgtoLabel">&nbsp;</h5>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
               </div>
               <div class="modal-body py-0">
                  <div class="row text-center">
                     <div class="col-2 ps-0">
                        <div class="card cursor-pointer" @click="pagamento.formaPgto = 'DINHEIRO'" :class="pagamento.formaPgto == 'DINHEIRO' ? 'border-theme' : ''">
                           <div class="card-body px-1 py-2">
                              <img src="@/assets/images/pagamentos/dinheiro.png" height="35" @error="imageError" />
                              <h5 class="font-12 mb-0 mt-2 text-uppercase d-none d-md-block">
                                 <span class="limitador-1" :class="pagamento.formaPgto == 'DINHEIRO' ? 'color-theme' : ''">Dinheiro</span>
                              </h5>
                           </div>
                        </div>
                     </div>
                     <div class="col-2 px-0">
                        <div class="card cursor-pointer" @click="pagamento.formaPgto = 'DÉBITO'" :class="pagamento.formaPgto == 'DÉBITO' ? 'border-theme' : ''">
                           <div class="card-body px-1 py-2">
                              <img src="@/assets/images/pagamentos/debito.png" height="35" @error="imageError" />
                              <h5 class="font-12 mb-0 mt-2 text-uppercase d-none d-md-block">
                                 <span class="limitador-1" :class="pagamento.formaPgto == 'DÉBITO' ? 'color-theme' : ''">Débito</span>
                              </h5>
                           </div>
                        </div>
                     </div>
                     <div class="col-2 pe-0">
                        <div class="card cursor-pointer" @click="pagamento.formaPgto = 'CRÉDITO'" :class="pagamento.formaPgto == 'CRÉDITO' ? 'border-theme' : ''">
                           <div class="card-body px-1 py-2">
                              <img src="@/assets/images/pagamentos/credito.png" height="35" @error="imageError" />
                              <h5 class="font-12 mb-0 mt-2 text-uppercase d-none d-md-block">
                                 <span class="limitador-1" :class="pagamento.formaPgto == 'CRÉDITO' ? 'color-theme' : ''">Crédito</span>
                              </h5>
                           </div>
                        </div>
                     </div>
                     <div class="col-2 pe-0">
                        <div class="card cursor-pointer" @click="pagamento.formaPgto = 'PIX'" :class="pagamento.formaPgto == 'PIX' ? 'border-theme' : ''">
                           <div class="card-body px-1 py-2">
                              <img src="@/assets/images/pagamentos/pix.png" height="35" @error="imageError" class="p-1" />
                              <h5 class="font-12 mb-0 mt-2 text-uppercase d-none d-md-block">
                                 <span class="limitador-1" :class="pagamento.formaPgto == 'PIX' ? 'color-theme' : ''">PIX</span>
                              </h5>
                           </div>
                        </div>
                     </div>
                     <div class="col-2 pe-0">
                        <div class="card cursor-pointer" @click="pagamento.formaPgto = 'CONVÊNIO'" :class="pagamento.formaPgto == 'CONVÊNIO' ? 'border-theme' : ''">
                           <div class="card-body px-1 py-2">
                              <img src="@/assets/images/pagamentos/convenio.png" height="35" @error="imageError" />
                              <h5 class="font-12 mb-0 mt-2 text-uppercase d-none d-md-block">
                                 <span class="limitador-1" :class="pagamento.formaPgto == 'CONVÊNIO' ? 'color-theme' : ''">Convênio</span>
                              </h5>
                           </div>
                        </div>
                     </div>
                     <div class="col-2 pe-0">
                        <div class="card cursor-pointer" @click="pagamento.formaPgto = 'OUTROS MEIOS'" :class="pagamento.formaPgto == 'OUTROS MEIOS' ? 'border-theme' : ''">
                           <div class="card-body px-1 py-2">
                              <img src="@/assets/images/pagamentos/moedas.png" height="35" @error="imageError" />
                              <h5 class="font-12 mb-0 mt-2 text-uppercase d-none d-md-block">
                                 <span class="limitador-1" :class="pagamento.formaPgto == 'OUTROS MEIOS' ? 'color-theme' : ''">Outros meios</span>
                              </h5>
                           </div>
                        </div>
                     </div>
                     <div class="col-12 px-0 text-start mt-2">
                        <div class="card">
                           <div class="card-body p-2">
                              <div class="input-group mb-1">
                                 <span class="input-group-text" id="totalProdutos"><i class="fal fa-equals color-theme font-22 me-3"></i><span class="font-14">Total dos produtos:</span></span>
                                 <input type="text" class="form-control" :value="'R$ '+ parseFloat(pdv.totalProdutos).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2})" aria-describedby="totalProdutos" readonly />
                              </div>
                              <div class="input-group mb-1" v-if="pdv.totalProdutos - pagamento.totalPagamentos > 0">
                                 <span class="input-group-text" id="valorRestante"><i class="far fa-wallet color-theme font-18 me-3"></i><span class="font-14">Valor restante:</span></span>
                                 <input type="text" class="form-control" :value="'R$ '+ parseFloat(pdv.totalProdutos - pagamento.totalPagamentos).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2})" aria-describedby="valorRestante" readonly />
                              </div>
                              <div class="input-group mb-1" v-else-if="pdv.totalProdutos - pagamento.totalPagamentos < 0">
                                 <span class="input-group-text" id="valorTroco"><i class="fal fa-coins color-theme font-18 me-3"></i><span class="font-14">Troco:</span></span>
                                 <input type="text" class="form-control" :value="'R$ '+ parseFloat(pagamento.totalPagamentos - pdv.totalProdutos).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2})" aria-describedby="valorTroco" readonly />
                              </div>
                              <div class="input-group mb-1" v-if="pagamento.formaPgto == 'CONVÊNIO'">
                                 <span class="input-group-text" id="cpfConvenio"><i class="far fa-address-card color-theme me-1"></i></span>
                                 <input type="text" class="form-control" v-model="pagamento.cpf" placeholder="CPF do usuário" aria-describedby="cpfConvenio" />
                              </div>
                              <div class="input-group mb-1" v-if="pagamento.formaPgto == 'CONVÊNIO'">
                                 <span class="input-group-text" id="codConvenio"><i class="far fa-barcode color-theme me-1"></i></span>
                                 <input type="text" class="form-control" :class="pagamento.codigo.length > 0 ? 'key': ''" v-model="pagamento.codigo" placeholder="Senha do convênio" aria-describedby="codConvenio" />
                              </div>
                              <div class="input-group">
                                 <span class="input-group-text" id="addPgto"><i class="far fa-money-bill-wave-alt color-theme"></i></span>
                                 <input type="text" class="form-control" v-focus v-model="pagamento.valor" @keyup.enter="addPagamento" placeholder="Valor do pagamento" ref="addPgto" aria-describedby="addPgto" />
                                 <button type="button" class="btn btn-outline-primary" @click="addPagamento"><i class="far fa-plus font-11 me-1"></i> Adicionar</button>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="col-12 px-0"><hr class="my-12 border-theme"></div>

                     <pagamento v-for="(pagamento, index) in pdv.pagamentos" :key="index" :pagamento="pagamento" :index="index" :showEdit="true"
                        @imprimirConvenio="imprimirConvenio($event)" @remover="executarFuncao('Cancelar pagamento', $event)" />

                     <div class="col-12 px-0 text-center" v-if="pdv.pagamentos.length == 0">
                        <div class="card">
                           <div class="card-body py-5">
                              <i class="fal fa-handshake-slash font-60 text-secondary opacity-50 d-block mb-3"></i>
                              <h4 class="font-12 mt-4 mb-0 text-secondary text-uppercase">Nenhum pagamento encontrado</h4>
                           </div>
                        </div>
                     </div>
                  </div>
					</div>
					<div class="modal-footer border-0">
                  <button type="button" class="btn btn-outline-primary" @click="concluirCompra" :disabled="pdv.totalProdutos - pagamento.totalPagamentos > 0">
                     Concluir <span class="d-none d-sm-inline">compra</span>
                  </button>
                  <button type="button" class="btn btn-secondary me-0" data-bs-dismiss="modal">Fechar</button>
               </div>
				</div>
			</div>
		</div>

      <impressaoVenda id="venda" class="d-none" :venda="comprovantes.venda" v-if="comprovantes.venda != null" />
      <impressaoSangria id="sangria" class="d-none" :sangria="comprovantes.sangria" v-if="comprovantes.sangria != null" />
      <impressaoConvenio id="convenio" class="d-none" :convenio="comprovantes.convenio" v-if="comprovantes.convenio != null" />
      <impressaoFechamento id="fechamento" class="d-none" :fechamento="comprovantes.fechamento" v-if="comprovantes.fechamento != null" />
   </div>
</template>

<script>

import $ from 'jquery'
import Swal from 'sweetalert2'
import { mapState } from 'vuex'
import debounce from 'lodash.debounce'
import produto from '@/components/pdv/caixas/Produto.vue'
import pagamento from '@/components/pdv/caixas/Pagamento.vue'
import impressaoVenda from '@/components/impressao/ComprovanteVenda.vue'
import impressaoSangria from '@/components/impressao/ComprovanteSangria.vue'
import impressaoConvenio from '@/components/impressao/ComprovanteConvenio.vue'
import impressaoFechamento from '@/components/impressao/ComprovanteFechamento.vue'

export default {
   name: 'Caixa',
   data: function () {
		return {
         pdv: {
            "id": null, "numeroNota": null, "serie": null ,"nome": null, "ip": null, "idLoja": null, "numeroLoja": null, "nomeLoja": null, "numeroPDV": null, "versaoCarga": null, 
            "idOperador": null, "nomeOperador": null, "periodoAberto": false, "produtos": [], 'totalProdutos': '0.00', 'pagamentos': []
         },
         pesquisaProduto: {'id': null, 'idProduto': null, 'idPdv': null, 'codigo': null, 'nome': null, 'quantidade': 1, 'preco': '0.00', 'resultado': []},
         pagamento: {'formaPgto': 'DINHEIRO', 'valor': '', 'idPdv': null, 'codigo': '', 'cpf': '', 'totalPagamentos': 0},
         comprovantes: {'sangria': null, 'fechamento': null, 'convenio': null, 'venda': null}
      }
   },
   computed: {
      ... mapState({
         isCarregando: state => state.isCarregando,
         dadosUsuario: state => state.dadosUsuario,
         urlRest: state => state.urlRest,
			tabs: state => state.tabs
      })
   },
   watch: {
		$data: {
			handler: function(value) {
				this.$store.dispatch('attTabData', JSON.parse(JSON.stringify({'id': this.$route.params.idTab, 'data': value})))
			},
			deep: true
		},
		'$route.params.idTab': function () {
         this.buscarProdutosTimer('', this)
         this.buscarPdv()
      },
      'pagamento.valor': function (newVal) {
         this.$store.dispatch('formatValue', {'valor': String(newVal).replace(',', '.'), 'digitos': 2}).then((valor) => {
            this.pagamento.valor = valor
         })
      }
	},
   components: {
      produto, impressaoSangria, impressaoFechamento, impressaoConvenio, impressaoVenda, pagamento
	},
   methods: {
      imageError : function (e) {
         this.$store.dispatch('imageError', e)
      },
      abrirModalPgto : function () {
         this.pagamento = {
            'formaPgto': 'DINHEIRO', 'valor': '', 'idPdv': this.pdv.id, 'codigo': '', 'cpf': '',
            'totalPagamentos': this.pdv.pagamentos.reduce((total, p) => total + parseFloat(p.valor), 0)
         }
         $('#modalPgto').modal('show')
      },
      buscarPdv : function () {
         this.$store.dispatch('alternarTelaCarregamento', true)
         Object.assign(this.$data.pdv, this.$options.data.apply(this).pdv)

			this.$axios({
				method: 'get',
				url: this.urlRest +'pdv/getPdv',
				params: {
					id: this.$route.query.idPdv
				}
			}).then(response => {
            this.$route.query.numeroPdv = response.data.numeroPDV
            this.$route.query.numeroLoja = response.data.numeroLoja
            this.pdv = response.data

         }).catch((error) => {
				if (error.response != undefined) {
					if (error.response.status == 408) {
						this.$store.dispatch('deslogar')

						this.$toast.fire({
							icon: 'warning',
							title: 'Sessão expirada!'
						});
					} else {
						this.$toast.fire({
							icon: 'error',
							title: 'Erro: ' + error.response.statustes
						});
					}
				}
			}).finally(() => {
				this.$store.dispatch('alternarTelaCarregamento', false)
			});
      },
      buscarProdutos : function (e) {
         this.buscarProdutosTimer(e.target.value, this)
      },
      buscarProdutosTimer : debounce((valor, vm) => {
			if (valor.length > 0 && valor.length < 3) return

         Object.assign(vm.$data.pesquisaProduto, vm.$options.data.apply(vm).pesquisaProduto)

			vm.$axios({
				method: 'post',
				url: vm.urlRest +'pdv/searchProdutos',
				headers: {'Content-Type': 'application/json'},
				data: {'nome': valor, 'loja': vm.pdv.idLoja}
				
			}).then(response => {
            vm.pesquisaProduto.resultado = response.data
			})
		}, 1000),
      salvarProdutoTemp : function (produto) {
         if (produto.idProduto == null) produto = {'id': null, 'idProduto': produto.id, 'idPdv': this.pdv.id, 'codigo': produto.ean, 'quantidade': 1, 'preco': produto.preco}
         
			if (produto.id == null) this.$store.dispatch('alternarTelaCarregamento', true)

			this.$axios({
				method: 'post',
				url: this.urlRest +'pdv/saveProdutoTemp',
				headers: {'Content-Type': 'application/json'},
				data: produto

			}).then(() => {
				this.pesquisaProduto = {'id': null, 'idProduto': null, 'idPdv': null, 'codigo': null, 'nome': null, 'quantidade': 1, 'preco': '0.00', 'resultado': this.pesquisaProduto.resultado}
            this.pdv.totalProdutos = this.pdv.produtos.forEach(p => p.total = parseFloat(p.quantidade) * p.preco);
            this.pdv.totalProdutos = this.pdv.produtos.reduce((total, p) => total + parseFloat(p.total), 0)
            
				if (produto.id == null) this.buscarPdv()

			}).catch((error) => {
				if (error.response != undefined) {
					if (error.response.status == 408) {
						this.$store.dispatch('deslogar')

						this.$toast.fire({
							icon: 'warning',
							title: 'Sessão expirada!'
						});
					} else {
						this.$toast.fire({
							icon: 'error',
							title: 'Erro: ' + error.response.status
						});
					}
				}
			}).finally(() => {
				this.$store.dispatch('alternarTelaCarregamento', false)
			});
		},
      removerProdutoTemp : function (index) {
         this.$store.dispatch('alternarTelaCarregamento', true)

			this.$axios({
				method: 'post',
				url: this.urlRest +'pdv/deleteProdutoTemp',
				params: {
               id: this.pdv.produtos[index].id
            }
			}).then(() => {
				this.pdv.produtos.splice(index, 1)
            this.pdv.totalProdutos = this.pdv.produtos.reduce((total, p) => total + parseFloat(p.total), 0)

            this.$toast.fire({
               icon: 'success',
               title: 'Item excluído!'
            });
			}).catch((error) => {
				if (error.response != undefined) {
					if (error.response.status == 408) {
						this.$store.dispatch('deslogar')

						this.$toast.fire({
							icon: 'warning',
							title: 'Sessão expirada!'
						});
					} else {
						this.$toast.fire({
							icon: 'error',
							title: 'Erro: ' + error.response.status
						});
					}
				}
			}).finally(() => {
				this.$store.dispatch('alternarTelaCarregamento', false)
			});
      },
      verificarPermissao : function (permissao) {
			return new Promise((resolve, reject) => {
            if (this.dadosUsuario.usuarioPermissoes.includes(permissao)) {
               resolve(this.dadosUsuario.usuarioId)
            } else {
               this.$store.dispatch('validarSupervisor').then((idSupervisor) => resolve(idSupervisor)).catch(() => reject())
            }
			})
      },
      executarFuncao : function (funcao, parametro) {
         this.verificarPermissao(funcao).then((idSupervisor) => {
            switch (funcao) {
               case 'Abrir/Fechar caixa':
                  this.fecharPeriodo(idSupervisor)
               break;
               case 'Efetuar sangria':
                  this.efetuarSangria(idSupervisor)
               break;
               case 'Limpar compra':
                  this.limparCompra()
               break;
               case 'Excluir item compra':
                  this.removerProdutoTemp(parametro)
               break;
               case 'Cancelar pagamento':
                  this.removerPagamentoTemp(parametro)
               break;
            }
         })
      },
      fecharPeriodo : function (idSupervisor) {
         Swal.fire({
            icon: 'warning',
            title: 'Fechar caixa',
            text: 'Informe o saldo final',
            showCancelButton: true,
            confirmButtonText: 'Confirmar',
            cancelButtonText: `Cancelar`,
            input: 'text',
            inputPlaceholder: 'Valor total',
            didOpen: (el) => {
               $(el.querySelector(".swal2-input")).keyup((v) => {
                  this.$store.dispatch('formatValue', {'valor': String(v.target.value).replace(",", "."), 'digitos': 2}).then((valor) => {
                     v.target.value = valor
                  })
               });
            },
            inputValidator: (value) => {
               if (!value) {
                  return 'Valor inválido'
               }
            }
         }).then((result) => {
            if (result.isConfirmed) {
               this.$store.dispatch('alternarTelaCarregamento', true)

               this.$axios({
                  method: 'post',
                  url: this.urlRest +'pdv/savePeriodo',
                  headers: {'Content-Type': 'application/json'},
                  data: {"idPdv": this.pdv.id, "idSupervisor": idSupervisor, "aberto": false, "valor": result.value}
                  
               }).then((response) => {
                  if (this.dadosUsuario.usuarioPdv == this.pdv.id) {
                     this.$store.dispatch('saveUsuarioPdv', null)
                  }
                  
                  this.comprovantes.fechamento = response.data;

                  Swal.fire({
							icon: 'success',
							title: 'Fechamento realizado!',
							text: 'Imprimir comprovante?',
							showCancelButton: true,
							confirmButtonText: 'Sim',
							cancelButtonText: `Não`
							
						}).then((result) => {
							if (result.isConfirmed) {
                        setTimeout(() => {
                           this.$htmlToPaper('fechamento', {styles: ['./bootstrap.print.css']})
                           setTimeout(() => this.$store.dispatch('deleteTabData', this.tabs.findIndex(tab => tab.id == this.$route.params.idTab)), 250);
                        }, 250);
                     } else {
                        this.$store.dispatch('deleteTabData', this.tabs.findIndex(tab => tab.id == this.$route.params.idTab))
                     }
						})
               }).catch((error) => {
                  if (error.response != undefined) {
                     if (error.response.status == 408) {
                        this.$store.dispatch('deslogar')

                        this.$toast.fire({
                           icon: 'warning',
                           title: 'Sessão expirada!'
                        });
                     } else {
                        this.$toast.fire({
                           icon: 'error',
                           title: 'Erro: ' + error.response.status
                        });
                     }
                  }
               }).finally(() => {
                  this.$store.dispatch('alternarTelaCarregamento', false)
               });
            }
         });
      },
      efetuarSangria : function (idSupervisor) {
         Swal.fire({
            icon: 'warning',
            title: 'Retirar valor do caixa',
            text: 'Informe o valor',
            showCancelButton: true,
            confirmButtonText: 'Confirmar',
            cancelButtonText: `Cancelar`,
            input: 'text',
            inputPlaceholder: 'Valor',
            didOpen: (el) => {
               $(el.querySelector(".swal2-input")).keyup((v) => {
                  this.$store.dispatch('formatValue', {'valor': String(v.target.value).replace(",", "."), 'digitos': 2}).then((valor) => {
                     v.target.value = valor
                  })
               });
            },
            inputValidator: (value) => {
               if (!value || parseFloat(value) <= 0) {
                  return 'Valor inválido'
               }
            }
         }).then((result) => {
            if (result.isConfirmed) {
               this.$store.dispatch('alternarTelaCarregamento', true)

               this.$axios({
                  method: 'post',
                  url: this.urlRest +'pdv/efetuarSangria',
                  headers: {'Content-Type': 'application/json'},
                  data: {"idPdv": this.pdv.id, "idSupervisor": idSupervisor, "valor": result.value}
                  
               }).then((response) => {
                  this.comprovantes.sangria = response.data;
                  
                  Swal.fire({
							icon: 'success',
							title: 'Sangria gerada!',
							text: 'Imprimir comprovante?',
							showCancelButton: true,
							confirmButtonText: 'Sim',
							cancelButtonText: `Não`
							
						}).then((result) => {
							if (result.isConfirmed) {
								setTimeout(() => this.$htmlToPaper('sangria', {styles: ['./bootstrap.print.css']}), 250);
							}
						})
               }).catch((error) => {
                  if (error.response != undefined) {
                     if (error.response.status == 408) {
                        this.$store.dispatch('deslogar')

                        this.$toast.fire({
                           icon: 'warning',
                           title: 'Sessão expirada!'
                        });
                     } else {
                        this.$toast.fire({
                           icon: 'error',
                           title: 'Erro: ' + error.response.status
                        });
                     }
                  }
               }).finally(() => {
                  this.$store.dispatch('alternarTelaCarregamento', false)
               });
            }
         });
      },
      limparCompra : function () {
         this.$store.dispatch('alternarTelaCarregamento', true)

         this.$axios({
            method: 'post',
            url: this.urlRest +'pdv/deletePdvTemp',
            params: {
               id: this.pdv.id
            }
         }).then(() => {
            this.buscarPdv()

            this.$toast.fire({
               icon: 'success',
               title: 'Pedido cancelado!'
            });
         }).catch((error) => {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  this.$store.dispatch('deslogar')

                  this.$toast.fire({
                     icon: 'warning',
                     title: 'Sessão expirada!'
                  });
               } else {
                  this.$toast.fire({
                     icon: 'error',
                     title: 'Erro: ' + error.response.status
                  });
               }
            }
         }).finally(() => {
            this.$store.dispatch('alternarTelaCarregamento', false)
         });
      },
      setCliente : function () {
         Swal.fire({
            icon: 'warning',
            title: 'Informar CPF/CNPJ',
            text: 'Informe o número',
            showCancelButton: true,
            confirmButtonText: 'Confirmar',
            cancelButtonText: `Cancelar`,
            input: 'text',
            inputPlaceholder: 'CPF/CNPJ',
            inputValidator: (value) => {
               if (!/^\d{11}$/.test(value) && !/^\d{14}$/.test(value)) {
                  return 'Número inválido'
               }
            }
         }).then((result) => {
            if (result.isConfirmed) {
               this.$store.dispatch('alternarTelaCarregamento', true)

               this.$axios({
                  method: 'post',
                  url: this.urlRest +'pdv/setCliente',
                  headers: {'Content-Type': 'application/json'},
                  data: {"idPdv": this.pdv.id, "cpfCnpj": result.value}
                  
               }).then(() => {
                  this.pdv.cpfCnpjCliente = result.value

                  this.$toast.fire({
                     icon: 'success',
                     title: 'Cliente adicionado!'
                  });
               }).catch((error) => {
                  if (error.response != undefined) {
                     if (error.response.status == 408) {
                        this.$store.dispatch('deslogar')

                        this.$toast.fire({
                           icon: 'warning',
                           title: 'Sessão expirada!'
                        });
                     } else {
                        this.$toast.fire({
                           icon: 'error',
                           title: 'Erro: ' + error.response.status
                        });
                     }
                  }
               }).finally(() => {
                  this.$store.dispatch('alternarTelaCarregamento', false)
               });
            }
         });
      },
      addPagamento : function () {
         if (!/^[0-9.]+$/.test(this.pagamento.valor) || parseFloat(this.pagamento.valor) < 0.01) {
            this.$toast.fire({
               icon: 'error',
               title: 'Valor inválido!'
            });

            return
         }

         if (this.pagamento.formaPgto == 'CONVÊNIO') {
            this.addPagamentoConvenio()
            return
         }

         this.$store.dispatch('alternarTelaCarregamento', true)

         this.$axios({
            method: 'post',
            url: this.urlRest +'pdv/addPagamentoTemp',
            headers: {'Content-Type': 'application/json'},
            data: this.pagamento
            
         }).then((response) => {
            this.pdv.pagamentos = response.data;
            this.pagamento = {
               'formaPgto': this.pagamento.formaPgto, 'valor': '', 'idPdv': this.pdv.id, 'codigo': '', 'cpf': '',
               'totalPagamentos': this.pdv.pagamentos.reduce((total, p) => total + parseFloat(p.valor), 0)
            }
            
            this.$toast.fire({
               icon: 'success',
               title: 'Pagamento adicionado!'
            });
         }).catch((error) => {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  this.$store.dispatch('deslogar')

                  this.$toast.fire({
                     icon: 'warning',
                     title: 'Sessão expirada!'
                  });
               } else {
                  this.$toast.fire({
                     icon: 'error',
                     title: 'Erro: ' + error.response.status
                  });
               }
            }
         }).finally(() => {
            this.$store.dispatch('alternarTelaCarregamento', false)
         });
      },
      addPagamentoConvenio : function () {
         if (this.pagamento.cpf == null || String(this.pagamento.cpf).trim().length == 0 || this.pagamento.codigo == null || String(this.pagamento.codigo).trim().length == 0) {
            this.$toast.fire({
               icon: 'error',
               title: 'CPF ou senha inválido(s)!'
            });

            return
         }

         this.$store.dispatch('alternarTelaCarregamento', true)

         this.$axios({
            method: 'post',
            url: this.urlRest +'pdv/pagamentoConvenio',
            headers: {'Content-Type': 'application/json'},
            data: this.pagamento
            
         }).then((response) => {
            this.pdv.pagamentos = response.data;
            this.pagamento = {
               'formaPgto': this.pagamento.formaPgto, 'valor': '', 'idPdv': this.pdv.id, 'codigo': '', 'cpf': '',
               'totalPagamentos': this.pdv.pagamentos.reduce((total, p) => total + parseFloat(p.valor), 0)
            }
                  
            Swal.fire({
               icon: 'success',
               title: 'Pagamento adicionado!',
               text: 'Imprimir comprovante?',
               showCancelButton: true,
               confirmButtonText: 'Sim',
               cancelButtonText: `Não`
               
            }).then((result) => {
               if (result.isConfirmed) {
                  this.imprimirConvenio(response.data[0])
               }
            })
         }).catch((error) => {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  this.$store.dispatch('deslogar')

                  this.$toast.fire({
                     icon: 'warning',
                     title: 'Sessão expirada!'
                  });
               } else if (error.response.status == 409) {
                  this.$toast.fire({
                     icon: 'error',
                     title: 'Credenciais incorretas!'
                  });
               } else if (error.response.status == 400) {
                  this.$toast.fire({
                     icon: 'error',
                     title: 'Saldo insuficiente!'
                  });
               } else {
                  this.$toast.fire({
                     icon: 'error',
                     title: 'Erro: ' + error.response.status
                  });
               }
            }
         }).finally(() => {
            this.$store.dispatch('alternarTelaCarregamento', false)
         });
      },
      removerPagamentoTemp : function (index) {
         this.$store.dispatch('alternarTelaCarregamento', true)

			this.$axios({
				method: 'post',
				url: this.urlRest +'pdv/deletePagamentoTemp',
				params: {
               id: this.pdv.pagamentos[index].id
            }
			}).then(() => {
				this.pdv.pagamentos.splice(index, 1)
            this.pagamento.totalPagamentos = this.pdv.pagamentos.reduce((total, p) => total + parseFloat(p.valor), 0)

            this.$toast.fire({
               icon: 'success',
               title: 'Pagamento cancelado!'
            });
			}).catch((error) => {
				if (error.response != undefined) {
					if (error.response.status == 408) {
						this.$store.dispatch('deslogar')

						this.$toast.fire({
							icon: 'warning',
							title: 'Sessão expirada!'
						});
					} else {
						this.$toast.fire({
							icon: 'error',
							title: 'Erro: ' + error.response.status
						});
					}
				}
			}).finally(() => {
				this.$store.dispatch('alternarTelaCarregamento', false)
			});
      },
      imprimirConvenio : function (pagamento) {
         this.comprovantes.convenio = {
            'nome': pagamento.nome, 'cpfCnpj': pagamento.cpfCnpj, 'valor': pagamento.valor, 'data': pagamento.data, 
            'nomeLoja': this.pdv.nomeLoja, 'numeroLoja': this.pdv.numeroLoja, 'nomePdv': this.pdv.nome
         }

         setTimeout(() => this.$htmlToPaper('convenio', {styles: ['./bootstrap.print.css']}), 250);
      },
      concluirCompra : function () {
         this.$store.dispatch('alternarTelaCarregamento', true)

			this.$axios({
				method: 'post',
				url: this.urlRest +'pdv/concluirCompra',
				params: {
               idPdv: this.pdv.id
            }
			}).then((response) => {
            this.comprovantes.venda = response.data;
				this.buscarPdv()

            $('#modalPgto').modal('hide')
                  
            Swal.fire({
               icon: 'success',
               title: 'Compra realizada!',
               text: 'Imprimir comprovante?',
               showCancelButton: true,
               confirmButtonText: 'Sim',
               cancelButtonText: `Não`
               
            }).then((result) => {
               if (result.isConfirmed) {
                  setTimeout(() => this.$htmlToPaper('venda', {styles: ['./bootstrap.print.css']}), 250);
               }
            })
			}).catch((error) => {
				if (error.response != undefined) {
					if (error.response.status == 408) {
						this.$store.dispatch('deslogar')

						this.$toast.fire({
							icon: 'warning',
							title: 'Sessão expirada!'
						});
					} else {
						this.$toast.fire({
							icon: 'error',
							title: 'Erro: ' + error.response.status
						});
					}
				}
			}).finally(() => {
				this.$store.dispatch('alternarTelaCarregamento', false)
			});
      }
   },
   mounted() {
      this.buscarProdutosTimer('', this)
      this.buscarPdv()
	}
}

</script>

<style scoped>

.container-operador {
   height: calc(100% - 60px);
   display: flex;
   justify-content: center;
   align-items: center;
   overflow: hidden;
}

.container-produtos {
   height: calc(100% - 100px);
   overflow: auto;
}

.container-pesquisa {
   height: 220px;
   overflow-y: auto;
}

.container-lateral-50 {
   min-height: 250px;
   height: calc(100% - 270px);
}

@media (min-width: 992px) {
   #pdv {
      height: calc(100% - 55px);
   }
   .container-pesquisa {
      height: calc(100% - 45px);
   }
}

.input-group-text {
   background: #0000;
   padding-right: 5px;
   position: relative;
   border-radius: 3px;
   border-top-right-radius: 0 !important;
   border-bottom-right-radius: 0 !important;
   border-right: 0 !important
}

#valorRestante,
#valorRestante ~ *,
#valorTroco,
#valorTroco ~ *,
#totalProdutos,
#totalProdutos ~ * {
   cursor: not-allowed;
   background-color: rgb(0 0 0 / 5%) !important;
}

#valorRestante,
#valorRestante ~ *,
#valorTroco,
#valorTroco ~ *,
#totalProdutos,
#totalProdutos ~ *,
#convenio,
#convenio ~ *,
#addPgto,
#addPgto ~ * {
   border: none !important;
   border-radius: 3px;
}

#valorTroco ~ *,
#valorRestante ~ *,
#totalProdutos ~ *,
#convenio ~ *,
#addPgto ~ * {
   padding-top: 10px !important;
   padding-bottom: 10px !important;
   user-select: none !important;
}

.input-group-text ~ input {
   border-top-left-radius: 0 !important;
   border-bottom-left-radius: 0 !important;
   border-left: 0 !important;
   margin-left: 0 !important;
}

</style>